import { TransportType } from "./transport-type";
import { IZone } from "./zones";

export class CostCalculator {
  private static getOegkZones() {
    const zones = [
      { fromKilometer: 0, toKilometer: 5, basePrice: 10.35 },
      {
        fromKilometer: 6,
        toKilometer: Number.MAX_SAFE_INTEGER,
        basePrice: 0,
        kilometerPrice: 1.86,
        kilometerPriceStart: 0,
      },
    ];

    return zones;
  }

  private static getSozialfahrtCanWalk() {
    const zones: IZone[] = [
      { fromKilometer: 0, toKilometer: 5, basePrice: 10.35 },
      {
        fromKilometer: 6,
        toKilometer: Number.MAX_SAFE_INTEGER,
        basePrice: 0,
        kilometerPrice: 1.86,
        kilometerPriceStart: 0,
      },
    ];

    return zones;
  }

  private static getSozialfahrtCarryChairAndWheelChair() {
    const zones: IZone[] = [
      { fromKilometer: 0, toKilometer: 15, basePrice: 30 },
      { fromKilometer: 16, toKilometer: 30, basePrice: 60 },
      { fromKilometer: 31, toKilometer: 50, basePrice: 85 },
      { fromKilometer: 51, toKilometer: 75, basePrice: 110 },
    ];

    return zones;
  }

  public static getCostsForSozialfahrt(
    kilometer: number,
    startPostalCode: string,
    endPostalCode: string,
    transportType: string
  ) {
    if (
      startPostalCode === "6020" &&
      endPostalCode === "6020" &&
      transportType === TransportType.Walking
    ) {
      return (14.49).toFixed(2);
    }

    let zones;

    switch (transportType) {
      case TransportType.Walking:
        zones = CostCalculator.getSozialfahrtCanWalk();
        return CostCalculator.getZonePrice(zones, kilometer);

      case TransportType.Sitting:
      case TransportType.Wheelchair:
        // No calculation for this
        if (kilometer > 75) {
          return null;
        }

        zones = CostCalculator.getSozialfahrtCarryChairAndWheelChair();
        return CostCalculator.getZonePrice(zones, kilometer);

      default:
        return null;
    }
  }

  public static getCosts(
    kilometer: number,
    insurance?: string,
    startPostalCode?: string,
    endPostalCode?: string
  ) {
    if (startPostalCode === "6020" && endPostalCode === "6020") {
      return (14.49).toFixed(2);
    }

    const zones = CostCalculator.getOegkZones();
    return CostCalculator.getZonePrice(zones, kilometer);
  }

  private static getZonePrice(zones: IZone[], kilometer: number) {
    kilometer = Math.round(kilometer);

    const filteredZones = zones.filter(
      (x) => kilometer >= x.fromKilometer && kilometer <= x.toKilometer
    );

    if (filteredZones.length === 1) {
      const usedZone = filteredZones[0];

      let result = usedZone.basePrice;

      if (usedZone.kilometerPrice) {
        const kilometerPriceStart = usedZone.kilometerPriceStart ?? 0;

        result += (kilometer - kilometerPriceStart) * usedZone.kilometerPrice;
      }

      return result.toFixed(2);
    }

    return null;
  }
}
