import * as React from "react";
import { Alert, Card, Form } from "react-bootstrap";
import logo from "../images/oerk_tirol.jpg";
import { CostCalculator } from "./util/cost-calculator";

export interface IMainPageProps {}

export interface IMainPageState {
  transportReason: string;
  insurance: string;
  kilometer: number;
  startPostalCode: string;
  endPostalCode: string;
  transportType: string;
}

export class MainPage extends React.Component<IMainPageProps, IMainPageState> {
  constructor(props: IMainPageProps) {
    super(props);

    this.state = {
      transportReason: "",
      insurance: "",
      kilometer: 0,
      startPostalCode: "",
      endPostalCode: "",
      transportType: "",
    };
  }

  private useOegkPricing() {
    return this.state.insurance === "oegk" || this.state.insurance === "kuf";
  }

  getSozialfahrtText() {
    const costs = CostCalculator.getCostsForSozialfahrt(
      this.state.kilometer!,
      this.state.startPostalCode,
      this.state.endPostalCode,
      this.state.transportType
    );

    return (
      <React.Fragment>
        <Alert variant="warning">
          Die Transportkosten für Sozialfahrten müssen grundsätzlich selbst
          bezahlt werden.
        </Alert>

        <Alert variant="info">
          Kosten für den Transport:{" "}
          {costs
            ? "€ " + costs
            : "Müssen direkt mit der jeweiligen Bezirksstelle abgeklärt werden."}
        </Alert>
      </React.Fragment>
    );
  }

  getInsuranceText() {
    switch (this.state.insurance) {
      case "svs":
      case "vaeb":
        return (
          <React.Fragment>
            <Alert variant="success">
              Die Transportkosten können mit der Krankenkasse direkt abgerechnet
              werden. Die Rechnung wird dabei ohne Umweg an die Krankenkasse
              übermittelt.
            </Alert>
          </React.Fragment>
        );
      case "pva":
        return (
          <React.Fragment>
            <Alert variant="success">
              Die Transportkosten können mit der Krankenkasse{" "}
              <span className="font-weight-bold">nach Bewilligung</span> direkt
              abgerechnet werden. Die Rechnung wird dabei ohne Umweg an die
              Krankenkasse übermittelt.
            </Alert>
            <Alert variant="info">
              Kosten für den Transport: €{" "}
              {CostCalculator.getCosts(
                this.state.kilometer!,
                this.state.insurance
              )}
            </Alert>
          </React.Fragment>
        );

      case "oegk":
        return (
          <React.Fragment>
            <Alert variant="warning">
              Die Transportkosten können mit der Krankenkasse nicht direkt
              abgerechnet werden. Als Patient_in erhalten Sie eine Rechnung,
              welche vor der Einreichung selbst bezahlt werden muss.
            </Alert>
            <Alert variant="info">
              Mit ärztlicher Transportanweisung, welche auf <b>sitzend</b>{" "}
              ausgestellt ist und dem Zahlungsbelegung werden{" "}
              <span className="font-weight-bold">bis zu 100%</span> nach
              Einreichung von der Krankenkasse rückerstattet.
              <br />
              <br />
              Kosten für den Transport: €{" "}
              {CostCalculator.getCosts(
                this.state.kilometer!,
                this.state.insurance,
                this.state.startPostalCode,
                this.state.endPostalCode
              )}
            </Alert>
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <Alert variant="warning">
              Die Transportkosten können mit der Krankenkasse nicht direkt
              abgerechnet werden. Als Patient_in erhalten Sie eine Rechnung,
              welche vor der Einreichung selbst bezahlt werden muss.
            </Alert>
            <Alert variant="info">
              Mit ärztlicher Transportanweisung, welche auf <b>sitzend</b>{" "}
              ausgestellt ist und dem Zahlungsbelegung können die Kosten aber
              für eine (Teil-)Rückerstattung bei der Krankenkasse eingereicht
              werden.
              <br />
              <br />
              Kosten für den Transport: €{" "}
              {CostCalculator.getCosts(
                this.state.kilometer!,
                this.state.insurance
              )}
            </Alert>
          </React.Fragment>
        );
    }
  }

  getResultText() {
    switch (this.state.transportReason) {
      case "strahl":
      case "chemo":
      case "dialy":
        return (
          <Alert variant="success" className="my-4">
            Dieser Transport wird vollständig von allen Krankenkassen bezahlt.
          </Alert>
        );

      case "other":
        if (this.state.insurance && this.state.kilometer) {
          if (
            this.useOegkPricing() &&
            (!this.state.startPostalCode || !this.state.endPostalCode)
          ) {
            return null;
          }

          return this.getInsuranceText();
        }
        return null;

      case "sozial":
        if (
          this.state.transportType &&
          this.state.kilometer &&
          this.state.startPostalCode &&
          this.state.endPostalCode
        ) {
          return this.getSozialfahrtText();
        }
        return null;

      default:
        return null;
    }
  }

  getAdditionalFields() {
    if (this.state.transportReason === "other") {
      return (
        <div className="d-flex flex-column gap-2 mt-2">
          {this.getInsuranceField()}
          {this.getKilometerField()}
          {this.useOegkPricing() ? this.getPostalCodeFields() : null}
        </div>
      );
    } else if (this.state.transportReason === "sozial") {
      return (
        <div className="d-flex flex-column gap-2 mt-2">
          <React.Fragment>
            {this.getTransportTypeField()}
            {this.getKilometerField()}
            {this.getPostalCodeFields()}
          </React.Fragment>
        </div>
      );
    }
  }

  getKilometerField() {
    return (
      <Form.Group>
        <Form.Label>Fahrtkilometer</Form.Label>
        <Form.Control
          type="number"
          step="0.1"
          placeholder="Bitte eintragen"
          value={this.state.kilometer}
          onChange={(x) => {
            try {
              const parsedKilometer = parseInt(x.target.value);
              this.setState({ kilometer: parsedKilometer });
            } catch {
              console.warn("Could not parse kilometer value");
            }
          }}
        />
        <Form.Text className="text-muted">
          Kilometeranzahl vom Abhol- zum Zielort
        </Form.Text>
      </Form.Group>
    );
  }

  getPostalCodeFields() {
    return (
      <>
        <Form.Group>
          <Form.Label>Start PLZ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Bitte eintragen"
            value={this.state.startPostalCode}
            onChange={(x) => this.setState({ startPostalCode: x.target.value })}
          />
          <Form.Text className="text-muted">
            Postleitzahl der Startadresse
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Ziel PLZ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Bitte eintragen"
            value={this.state.endPostalCode}
            onChange={(x) => this.setState({ endPostalCode: x.target.value })}
          />
          <Form.Text className="text-muted">
            Postleitzahl der Zieladresse
          </Form.Text>
        </Form.Group>
      </>
    );
  }

  private getInsuranceField() {
    return (
      <Form.Group>
        <Form.Label>Krankenkasse</Form.Label>
        <Form.Control
          as="select"
          value={this.state.insurance}
          onChange={(x) => this.setState({ insurance: x.target.value })}
        >
          <option disabled={true} value="">
            Bitte wählen
          </option>
          <option value="oegk">
            ÖGK - Österreichische Gesundheitskasse (ehem. TGKK)
          </option>
          <option value="svs">
            SVS - Sozialversicherungsanstalt der Selbstständigen (ehem. SVA,
            SVB)
          </option>
          <option value="vaeb">
            VAEB - Versicherungsanstalt öffentlich Bediensteter (ehem. BVA, VA
            ÖBB)
          </option>
          <option value="pva">PVA - Pensionsversicherungsanstalt</option>
          <option value="kuf">KUF - Krankenfürsorgeanstalt</option>
          <option value="other">Andere Krankenkasse</option>
        </Form.Control>
      </Form.Group>
    );
  }

  private getTransportTypeField() {
    return (
      <Form>
        <Form.Group>
          <Form.Label>Transportmodus</Form.Label>
          <Form.Control
            as="select"
            value={this.state.transportType}
            onChange={(x) => this.setState({ transportType: x.target.value })}
          >
            <option disabled={true} value="">
              Bitte wählen
            </option>
            <option value="0">Gehfähig</option>
            <option value="1">Tragstuhl</option>
            <option value="2">Rollstuhl</option>
          </Form.Control>
        </Form.Group>
      </Form>
    );
  }

  componentDidMount(): void {
    this.handleParameters();
  }

  handleParameters() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("transportReason")) {
      this.setState({ transportReason: params.get("transportReason") ?? "" });
    }

    if (params.has("insurance")) {
      this.setState({ insurance: params.get("insurance") ?? "" });
    }

    if (params.has("kilometer")) {
      const kilometerParam = params.get("kilometer");
      this.setState({
        kilometer: kilometerParam !== undefined ? parseInt(kilometerParam!) : 0,
      });
    }

    if (params.has("startPostalCode")) {
      this.setState({ startPostalCode: params.get("startPostalCode") ?? "" });
    }

    if (params.has("endPostalCode")) {
      this.setState({ endPostalCode: params.get("endPostalCode") ?? "" });
    }

    if (params.has("transportType")) {
      this.setState({ transportType: params.get("transportType") ?? "" });
    }

    window.history.replaceState({}, document.title, "/");
  }

  public render() {
    return (
      <React.Fragment>
        <Card style={{ width: "36rem" }} className="m-3 shadow">
          <Card.Body>
            <img
              className="d-none d-md-block"
              id="card-image"
              src={logo}
              alt="Logo Rotes Kreuz Tirol"
            ></img>
            <Card.Title>Kostenrechner</Card.Title>

            <Card.Subtitle className="mb-2 text-muted">
              Mein Fahrdienst - Rotes Kreuz Tirol
            </Card.Subtitle>

            <Form className="mt-4">
              <Form.Group>
                <Form.Label>Grund für die Fahrt</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.transportReason}
                  onChange={(x) =>
                    this.setState({ transportReason: x.target.value })
                  }
                >
                  <option disabled={true} value="">
                    Bitte wählen
                  </option>
                  <option value="strahl">Strahlentherapie</option>
                  <option value="chemo">Chemotherapie</option>
                  <option value="dialy">Dialyse</option>
                  <option value="sozial">Sozialfahrt</option>
                  <option value="other">Anderer Grund</option>
                </Form.Control>
              </Form.Group>
            </Form>

            {this.getAdditionalFields()}
            <div className="mt-4">{this.getResultText()}</div>

            <Card.Text className="mt-4 mb-2 blockquote-footer">
              Die hier angeführten Kosten dienen zur ungefähren Abschätzung.
              Sollten Sie eine genaue Kostenauskunft benötigen, so kontaktieren
              Sie bitte Ihre Rot-Kreuz Bezirksstelle.
            </Card.Text>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

export default MainPage;
