import MainPage from "./components/main-page";

function App() {
  return (
    <div className="bg-image">
      <div className="centered">
        <MainPage />
      </div>
    </div>
  );
}

export default App;
